import React from "react"
import Layout from "../components/layout"
import { Row, Container, Col } from "react-bootstrap"

const Terms = () => (
  <Layout>
    <Container fluid className="px-0 main">
      <Container className="privacy py-5">
        <Row className="justify-content-center">
          <Col md="8">
            <h2 className="f-24 fw-600">Terms of Use</h2>
            <p>
              SpaceIt LLC (collectively with its affiliated companies,
              “spaceit,” “we,” “us” or “our”) has created a website at
              [www.spaceit.com], together with our online and/or mobile
              services, software, and other features we provide in connection
              with our Services (as defined below) (collectively, the “Website”
              or “Site”) that provides a technology software to the real estate
              industry. SpaceIt does not act as a broker or contracting agent
              for property owners or tenants. SpaceIt is not an owner or
              operator of any properties (such as commercial retail space or
              residential property) nor is it a provider of such properties, and
              SpaceIt does not own, sell, resell, furnish, provide, rent, re-
              rent, manage and/or control properties.
            </p>
            <p>
              To assist you in using the Website, we have created: (i) these
              Terms of Use (the “Terms”); and (ii) a Privacy Policy. Our Privacy
              Policy explains how we treat information that you provide to us
              through the Website, and our Terms govern your use of our Website
              and your use of our Services provided through the Website. Our
              Terms and Privacy Policy apply to anyone accessing our Website
              (collectively, “you”), including (a) casual visitors to our
              Website who do not sign up for an account on the Website (“Site
              Visitors”), and (b) users who have registered with SpaceIt to use
              the Website (“Authorized Users”). The terms “SpaceIt,” “we,” “us”
              and “our” refer to SpaceIt LLC.
            </p>
            <p>
              PLEASE READ THIS DOCUMENT CAREFULLY BEFORE YOU ACCESS OR USE THE
              WEBSITE OR OUR SERVICES. BY ACCESSING OR REGISTERING TO USE THE
              WEBSITE, YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DO NOT WISH
              TO BE BOUND BY THESE TERMS, PLEASE DO NOT ACCESS OUR WEBSITE OR
              REGISTER TO USE OUR SERVICES.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>1. Your Agreement.</strong> These Terms govern: (i) your
              use of the Website; (ii) Authorized Users' receipt of, and use of,
              SpaceIt' services offered through the Website, including those set
              out in Section 2 (Our Services; Overview) (collectively, the
              “Services”); (iii) Authorized Users' provision of content,
              including, but not limited to, profile information, property
              information, photographs, images, videos, comments, questions, and
              other content or information (collectively, “User Content”); and
              (iv) your use of information obtained through the Website,
              including information, software, artwork, text, video, audio,
              pictures, content, trademarks, trade dress and other intellectual
              property owned by SpaceIt or our licensors and made available to
              you through the Website or Services (collectively, “SpaceIt
              Content”). Please read these Terms carefully; they impose legal
              obligations on you and on SpaceIt,and establish our legal
              relationship. By using the Services or accessing our Website, you
              are acknowledging that you have read and understood these Terms
              and agree to be legally bound by them.
            </p>
            <p>
              While you can visit the Website and review a range of information
              about SpaceIt, our Website and our Services without registering,
              to use the Services, we require that you become an Authorized
              User. Authorized Users are required to confirm their agreement to
              be bound by these Terms during the registration process (and from
              time to time as we may require) by clicking an “I Accept” button.
            </p>
            <p>
              If you are agreeing to these Terms and our Privacy Policy on
              behalf of a company or other legal entity (your “Organization”):
              (i) you represent and warrant that you have authority to act on
              behalf of, and to bind your Organization; and (ii) for all
              purposes in these Terms and the Privacy Policy, the term “you”
              means your Organization on whose behalf you are acting.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>2. Our Services; Overview.</strong> The Website provides
              property technology software that helps real estate brokers,
              developers, and landlords lease or sell properties faster and more
              effectively. Authorized Users will have access to (i)
              sophisticated call routing and tracking; (ii) caller insights; and
              (iii) SpaceIt's customizable Virtual Assistant. Each Authorized
              User will only be granted access to the Website to the extent
              appropriate for his/her relationship with SpaceIt, and only in
              accordance with Section 6.2 (Grant of Rights to Authorized Users).
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>3. Mobile Software.</strong> We may make available
              software to access the Service via a mobile device (“Mobile
              Software”). To use the Mobile Software, you must have a mobile
              device that is compatible with the Mobile Software. SpaceIt does
              not warrant that the Mobile Software will be compatible with your
              mobile device. SpaceIt hereby grants you a non-exclusive,
              non-transferable, revocable license to use a compiled code copy of
              the Mobile Software for one SpaceIt account on one mobile device
              owned or leased solely by you, for your personal use. You may not:
              (i) modify, disassemble, decompile or reverse engineer the Mobile
              Software, except to the extent that such restriction is expressly
              prohibited by law; (ii) rent, lease, loan, resell, sublicense,
              distribute or otherwise transfer the Mobile Software to any third
              party or use the Mobile Software to provide time sharing or
              similar services for any third party; (iii) make any copies of the
              Mobile Software; (iv) remove, circumvent, disable, damage or
              otherwise interfere with security-related features of the Mobile
              Software, features that prevent or restrict use or copying of any
              content accessible through the Mobile Software, or features that
              enforce limitations on use of the Mobile Software; or (v) delete
              the copyright and other proprietary rights notices on the Mobile
              Software. You acknowledge that SpaceIt may from time to time issue
              upgraded versions of the Mobile Software, and may automatically
              electronically upgrade the version of the Mobile Software that you
              are using on your mobile device. You consent to such automatic
              upgrading on your mobile device, and agree that the terms and
              conditions of these Terms will apply to all such upgrades. Any
              third-party code that may be incorporated in the Mobile Software
              is covered by the applicable open source or third-party license
              EULA, if any, authorizing use of such code. The foregoing license
              grant is not a sale of the Mobile Software or any copy thereof,
              and SpaceIt or its third party partners or suppliers retain all
              right, title, and interest in the Mobile Software (and any copy
              thereof). Any attempt by you to transfer any of the rights, duties
              or obligations hereunder, except as expressly provided for in
              these Terms, is null and void. SpaceIt reserves all rights not
              expressly granted under these Terms.{" "}
            </p>
            <p>
              The Mobile Software originates in the United States, and is
              subject to United States export laws and regulations. The Mobile
              Software may not be exported or re-exported to certain countries
              or those persons or entities prohibited from receiving exports
              from the United States. In addition, the Mobile Software may be
              subject to the import and export laws of other countries. You
              agree to comply with all United States and foreign laws related to
              use of the Mobile Software and the SpaceIt Service. If the Mobile
              Software is being acquired on behalf of the United States
              Government, then the following provision applies: use,
              duplication, or disclosure of the Mobile Software by the U.S.
              Government is subject to restrictions set forth in these Terms and
              as provided in DFARS 227.7202-1(a) and 227.7202-3(a) (1995), DFARS
              252.227-7013(c)(1)(ii) (OCT 1988), FAR 12.212(a) (1995), FAR
              52.227-19, or FAR 52.227-14 (ALT III), as applicable.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>
                4. Obtaining a Password; Protecting Your Password.
              </strong>
            </p>
            <p>
              <strong>4.1.Obtaining and Using a Password.</strong> Only
              Authorized Users who have a valid password are authorized to
              access the password-protected areas of the Website. Authorized
              Users must keep in mind that we will treat anyone who uses their
              user name and password as that user. We will provide this
              individual with all of the rights and privileges that we provide
              to that Authorized User, and we will hold that Authorized User
              responsible for the activities of a person using the Authorized
              User's password. We therefore recommend that Authorized Users
              maintain their user name and password in confidence, and that they
              refrain from disclosing this information to anyone who might
              “pretend” to be that Authorized User with respect to the Website
              and their use of the Services. We also ask that Authorized Users
              notify us immediately if they suspect that someone is using their
              user name and/or password in this or any other inappropriate
              manner.
            </p>
            <p>
              <strong>4.2 Limitation on Number of Accounts.</strong> Each
              Authorized User shall only have one SpaceIt account and, in the
              case of Organizations, each Organization shall only have one
              SpaceIt account. SpaceIt reserves the right to suspend, terminate,
              or otherwise disable access to an account if it determines in its
              reasonable discretion that this policy has been violated.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>5. Ownership; Reservation of Rights.</strong> The Website,
              Services, and associated SpaceIt Content are the proprietary
              property of SpaceIt and our licensors, and are protected by U.S.
              and international copyright and other intellectual property laws,
              or are used under the principles of fair use. SpaceIt and our
              licensors retain all rights with respect to the Website, Services
              and SpaceIt Content except those expressly granted to you in these
              Terms.
            </p>
            <p></p>
            <p></p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>6. Grant of Rights.</strong>
            </p>
            <p>
              <strong>6.1. Grant of Rights to Site Visitors.</strong> As a Site
              Visitor, subject to your compliance with these Terms, you are
              granted the right to access our Website solely for your personal,
              non-commercial use. You may not: (i) access, view, modify or
              otherwise use any portion of our Website not made expressly
              accessible to you; or (ii) modify, copy, distribute or otherwise
              use the Website, SpaceIt Content or Services without our express
              permission.
            </p>
            <p>
              <strong>6.2. Grant of Rights to Authorized Users.</strong> As a
              Authorized User, subject to your compliance with these Terms, you
              are granted a limited, non-exclusive, non-transferable, worldwide
              right to access, execute, display, perform, and otherwise use the
              Website solely for your personal or your Organization's internal
              use of the Services, provided that you shall not: (i) license,
              sublicense, sell, resell, distribute or otherwise commercially
              exploit the Website or the Services to any third party; (ii)
              modify or make derivative works based upon the Website, SpaceIt
              Content, or Services; or (iii) reverse engineer, reverse compile,
              or access the Website or Services in order to build a competitive
              product or service. You may access and view certain
              password-protected areas of our Website for use solely in
              conjunction with the Services and as provided in these Terms, and
              you may not otherwise modify, copy, distribute, or otherwise use
              the Website, SpaceIt Content or Services.
            </p>
            <p>
              <strong>6.3. Duration of Rights for Authorized Users.</strong> As
              a Authorized User, you will continue to enjoy your rights under
              Section 6.2 (Grant of Rights to Authorized Users) for as long as
              you are a Authorized User, unless your password is revoked or
              suspended, as set out in Section 12 (Monitoring; Revocation or
              Suspension of Use Privileges).
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>7. Authorized User Grant of Rights.</strong>
            </p>
            <p>
              <strong>7.1. Grant of Rights to SpaceIt in User Content.</strong>{" "}
              By submitting User Content in connection with our Services, you
              grant SpaceIt the right to use, copy, reformat, index, modify,
              display and distribute your User Content for the purposes of
              providing our Services. No compensation will be paid with respect
              to SpaceIt's use of your User Content.
            </p>
            <p>
              <strong>
                7.2. Rights/Permissions You Must Have in Your User Content.
              </strong>{" "}
              In submitting User Content, you represent and warrant that you
              have sufficient authority and rights to post such User Content,
              and to provide the grant of rights to us in Section 7.1 (Grant of
              Rights to SpaceIt in User Content).
            </p>
            <p>
              <strong>7.3. Right to Decline User Content.</strong> We expressly
              reserve the right to refuse to use, remove, or disable User
              Content that we conclude, in our sole discretion, violates these
              Terms or our Privacy Policy, or is incompatible with the purposes
              of the Website and our Services.
            </p>
            <p>
              <strong>7.4. Authorized User Comments/Feedback.</strong> Our
              Website may allow Authorized Users to provide comments or feedback
              regarding our Website and our Services. By providing
              comments/feedback, you grant us the right to use your comments and
              feedback for the purposes of improving the Website and our
              Services.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>8. User Content.</strong> You represent and warrant: (i)
              that you have all necessary rights and authorizations to post any
              User Content you post on the Website; and (ii) the accuracy of the
              information you provide as part of the User Content. SpaceIt does
              not validate User Content in any way but may, in its sole
              discretion but without any obligation to do so, remove User
              Content that is alleged to have been submitted in violation of
              these Terms. SpaceIt may require additional evidence of compliance
              with this these Terms from users who are alleged to have submitted
              User Content or other information in violation of these Terms.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>9. Payment Obligations.</strong>
            </p>
            <p>
              <strong>9.1 Billing Policies.</strong> Certain aspects of the
              Services may be provided for a fee or other charge. If you elect
              to use paid aspects of the Services, you agree to the pricing and
              payment terms, as we may update them from time to time. For
              instance, SpaceIt may charge service fees for the technology and
              other services it provides. SpaceIt may add new services for
              additional fees and charges, or amend fees and charges for
              existing services, at any time in its sole discretion. Any charges
              will be billed to your credit card on file with SpaceIt or
              invoiced to you, at SpaceIt's discretion.
            </p>
            <p>
              Unless otherwise agreed by the parties in writing, SpaceIt will
              invoice you or charge your credit card for the applicable fees on
              a monthly basis in advance. If you dispute any payment made
              hereunder, you must notify SpaceIt in writing within thirty (30)
              days of such payment. Failure to so notify SpaceIt shall result in
              the waiver by you of any claim relating to such disputed payment.{" "}
            </p>
            <p>
              <strong>9.2 No Refunds.</strong> You may cancel your SpaceIt
              account at any time; however, there are no refunds for
              cancellation after any charges have been incurred. In the event
              that SpaceIt suspends or terminates your account or these Terms,
              you understand and agree that you shall receive no refund or
              exchange, any unused time on a subscription, any license or
              subscription fees for any portion of the Services, any content or
              data associated with your account, or for anything else.
            </p>
            <p>
              <strong>9.3 Payment Information; Taxes.</strong> All information
              that you provide in connection with a purchase or transaction or
              other monetary transaction interaction with the Services must be
              accurate, complete, and current. You agree to pay all charges
              incurred by users of your credit card, debit card, or other
              payment method used in connection with a purchase or transaction
              or other monetary transaction interaction with the Services at the
              prices in effect when such charges are incurred. You will pay any
              applicable taxes, if any, relating to any such purchases,
              transactions or other monetary transaction interactions.
            </p>
            <p>
              <strong>9.4 Mobile Charges.</strong> You may use mobile data in
              connection with SpaceIt's mobile software applications and/or sign
              up to receive certain SpaceIt notifications or information via
              text messaging or other features SpaceIt may offer from time to
              time. You agree that if you provide SpaceIt with your phone
              number, we may use it to contact you via voice calls or text
              messages. You may incur additional charges from your wireless
              provider for these services. You agree that you are solely
              responsible for any such charges.
            </p>
            <p>
              <strong>9.5 California Residents.</strong> SpaceIt is the provider
              of the Services. SpaceIt's corporate and contact information is
              provided herein. If you are a California resident, in accordance
              with Cal. Civ. Code § 1789.3, you may report complaints to SpaceIt
              at info@spaceit.com or to the Complaint Assistance Unit of the
              Division of Consumer Services of the California Department of
              Consumer Affairs by contacting them in writing at 1625 North
              Market Blvd., Suite N 112, Sacramento, CA 95834, or by telephone
              at (800) 952-5210 or (916) 445-1254.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>10. Electronic Communications.</strong> You consent to
              receive communications from us electronically in accordance with
              our Privacy Policy. We will communicate with you by email or by
              providing notices via the Website. You agree that all agreements,
              notices, disclosures and other communications that we provide to
              you electronically satisfy any legal requirement that these
              communications be in writing.
            </p>
            <p></p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>11. Code of Conduct.</strong> As a condition to your use
              of the Website and the Services, you agree to follow our Code of
              Conduct, set out below. Under this Code, you will not:
            </p>
            <ul>
              <li className="pb-3">
                Create, upload or otherwise transmit any User Content, including
                profile information or any messages, containing false or
                misleading information.
              </li>
              <li className="pb-3">
                Disguise, shield or otherwise try to hide your identity by
                provision of false information, use of virtual private networks,
                IP spoofing, and other techniques.
              </li>
              <li className="pb-3">
                Upload, email or otherwise transmit any images or other User
                Content that is unlawful, obscene, harmful, hateful, invades the
                privacy of any third party, contains nudity or pornography or is
                otherwise objectionable.
              </li>
              <li className="pb-3">
                Disseminate materials that impact or invade the privacy of
                others, such as photographs, video clips, sound recordings,
                personally identifiable information, or other materials that
                reveal personal, private or sensitive information about another
                person, without that person's consent.
              </li>
              <li className="pb-3">
                Make use of another Authorized User's User Content without the
                other Authorized User's permission.
              </li>
              <li className="pb-3">Create multiple accounts.</li>
              <li className="pb-3">
                Submit material that is intentionally false, defamatory,
                unlawfully threatening or unlawfully harassing.
              </li>
              <li className="pb-3">
                Subject to the consent of the person concerned, submit, upload,
                email or otherwise transmit any personally identifiable
                information, or other materials that reveal personal, private or
                sensitive information about such person that is inaccurate.
              </li>
              <li className="pb-3">
                Infringe any third party's copyright, patent, trademark, trade
                secret or other proprietary rights or rights of publicity or
                privacy. Electronic materials - such as music, videos, games,
                images and text in electronic form - can easily be copied,
                modified and sent over networks (such as the internet). These
                electronic materials are thus extremely vulnerable to
                unauthorized distribution and copyright infringement. These
                materials may not be transmitted over the Website without the
                copyright owner's permission or without a legitimate “fair use”
                justification for the transmittal.
              </li>
              <li className="pb-3">
                Transmit materials that contain any viruses, Trojan horses,
                worms, time bombs, cancelbots or other computer-programming
                routines that are intended to damage, detrimentally interfere
                with, surreptitiously intercept or expropriate any system, data
                or personal information.
              </li>
              <li className="pb-3">
                Use the Website to artificially generate traffic or page links
                to a website or for any other purpose not expressly allowed
                under these Terms.
              </li>
              <li className="pb-3">
                Use the Website in a manner that could disable, overburden or
                impair the Website or Services or interfere with any other
                party's use and enjoyment of the Website and Services, such as
                through sending “spam.”
              </li>
              <li className="pb-3">
                Use the Website to test or reverse engineer the Website in order
                to find limitations, vulnerabilities or to evade filtering
                capabilities.
              </li>
              <li className="pb-3">
                Seek to obtain access to any materials or information through
                “hacking”, “data harvesting” or through other means we have not
                intentionally made available to you through the Website.
              </li>
              <li className="pb-3">
                Use the Website for any purpose that is unlawful or prohibited
                by these Terms. For example, you will not use the Website to
                violate any law, statute or regulation (including, without
                limitation, those governing export control, consumer protection,
                unfair competition, anti-discrimination or false advertising).
              </li>
            </ul>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>
                12. Monitoring; Revocation or Suspension of Use Privileges.
              </strong>{" "}
              We reserve the right at any time to: (i) monitor your use of the
              Website; and (ii) terminate or suspend your use of some or all of
              the Website or Services if you engage in activities that we
              conclude, in our discretion, breach our Code of Conduct or
              otherwise violate these Terms or our Privacy Policy. Although we
              have no, and assume no, obligation to monitor activities on the
              Website, please understand that we may employ filters designed to
              detect and block inappropriate content described in our Code of
              Conduct. We reserve the right to request edits to your submission,
              to refuse to post or to remove any information or materials, in
              whole or in part, that we believe, in our sole discretion, is
              incompatible with our Code of Conduct.
            </p>
            <p>
              You should also understand that our Code of Conduct is based in
              many instances on principles of applicable law. Violations of our
              Code of Conduct accordingly may expose you to criminal charges,
              and civil liability to harmed parties for compensatory damages and
              attorney's fees. SpaceIt reserves the right at all times to
              disclose information it deems necessary to satisfy any applicable
              law, regulation, legal process or governmental request, consistent
              with our Privacy Policy.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>13. Links to Third-Party Sites.</strong> The Website may
              contain links or produce search results that reference links to
              third party websites (collectively, “Linked Sites”). SpaceIt has
              no control over these Linked Sites or their content and does not
              assume responsibility or liability for any content, opinions or
              materials available on Linked Sites. SpaceIt does not endorse the
              content of any Linked Site, nor does SpaceIt warrant that a Linked
              Site will be free of computer viruses or other harmful code that
              can impact your computer or other web-access device. By using the
              Website to search for or link to another site, you agree and
              understand that such use is at your own risk.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>14. User Disputes.</strong> SpaceIt is not responsible for
              and is not liable for the User Content or conduct of Authorized
              Users. You are solely responsible for your User Content, conduct,
              and interaction with other Authorized Users, both online or
              offline. We have no obligation to become involved in disputes
              between Authorized Users. If you have a dispute with one or more
              Authorized Users, you release SpaceIt (and our officers,
              directors, agents, employees, subsidiaries and affiliates) from
              claims, demands and damages (actual and consequential) of every
              kind and nature, known and unknown, arising out of or in any way
              connected with such dispute.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>15. Warranty Disclaimer. </strong> Spaceit DOES NOT
              PROMISE THAT THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED, OR
              THAT YOU WILL OBTAIN SPECIFIC RESULTS FROM YOUR USE OF THE WEBSITE
              AND OUR SERVICES OR YOUR USE OF ANY CONTENT, SEARCH OR LINK ON IT.
              THE WEBSITE, SERVICES AND CONTENT WITHIN ARE DELIVERED ON AN “AS
              IS” AND “AS AVAILABLE” BASIS. WHEN YOU ACCESS THE WEBSITE AND
              SERVICES, YOU DO SO AT YOUR OWN RISK. SPACEIT DOES NOT REPRESENT
              OR WARRANT THAT MATERIALS YOU DOWNLOAD FROM THE WEBSITE WILL BE
              FREE OF VIRUSES OR OTHER HARMFUL FEATURES.
            </p>
            <p>
              THE INFORMATION PROVIDED ON THE WEBSITE IS FOR GENERAL
              INFORMATIONAL PURPOSES ONLY AND IS NOT INTENDED AS REAL ESTATE,
              LEGAL, TAX, ACCOUNTING, OR OTHER PROFESSIONAL ADVICE, NOR IS IT A
              SUBSTITUTE FOR PROFESSIONAL ADVICE.
            </p>
            <p>
              SPACEIT EXPRESSLY DISCLAIMS: (i) ALL WARRANTIES, EXPRESS OR
              IMPLIED, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY AND
              FITNESS FOR A PARTICULAR PURPOSE; (ii) ANY RESPONSIBILITY OR
              LIABILITY FOR THE ACCURACY, CONTENT, COMPLETENESS OR LEGALITY OF
              INFORMATION AVAILABLE THROUGH THE WEBSITE; AND (iii) ANY
              RESPONSIBILITY OR LIABILITY FOR HARM RESULTING FROM DOWNLOADING OR
              ACCESSING INFORMATION THROUGH THE WEBSITE, INCLUDING HARM CAUSED
              BY VIRUSES OR SIMILAR DESTRUCTIVE FEATURES. YOU EXPRESSLY AGREE
              THAT USE OF THE WEBSITE AND RELATED SERVICES AND CONTENT IS AT
              YOUR SOLE RISK.
            </p>
            <p>
              FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT
              ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO
              SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>16. Limitation of Liability.</strong> TO THE MAXIMUM
              EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL SPACEIT, ITS
              AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR LICENSORS
              BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
              CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
              DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
              INTANGIBLE LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR
              INABILITY TO USE, THE WEBSITE OR THE SERVICES. UNDER NO
              CIRCUMSTANCES WILL SPACEIT BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR
              INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED
              ACCESS OR USE OF THE WEBSITE OR THE SERVICES OR YOUR ACCOUNT OR
              THE INFORMATION CONTAINED THEREIN.
            </p>
            <p>
              PLEASE NOTE THAT, AS STATED HEREIN, SPACEIT IS INTENDED TO BE USED
              AS A TECHNOLOGY SERVICE TO help real estate brokers, developers,
              AND LANDLORDS lease OR SELL properties faster and more
              effectively] SPACEIT CANNOT AND DOES NOT CONTROL THE CONDITION,
              LEGALITY, OR SUITABILITY OF ANY PROPERTY OR USER CONTENT. SPACEIT
              IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED
              TO ANY AND ALL POSTINGS AND PROPERTY RENTALS. ACCORDINGLY, ANY
              AGREEMENTS WILL BE MADE AT THE RISK OF ANY TENANT AND ANY OWNER.
            </p>
            <p>
              TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SPACEIT ASSUMES
              NO LIABILITY OR RESPONSIBILITY FOR ANY: (I) ERRORS, MISTAKES, OR
              INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE,
              OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF
              OUR WEBSITE OR SERVICES; (III) ANY UNAUTHORIZED ACCESS TO OR USE
              OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
              STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION
              TO OR FROM OUR WEBSITE OR SERVICES; (V) ANY BUGS, VIRUSES, TROJAN
              HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR
              WEBSITE OR SERVICES BY ANY THIRD PARTY; (VI) ANY ERRORS OR
              OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE INCURRED AS A
              RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
              OTHERWISE MADE AVAILABLE THROUGH OUR WEBSITE OR SERVICES; AND/OR
              (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
              CONDUCT OF ANY THIRD PARTY. IN NO EVENT SHALL SPACEIT, ITS
              AFFILIATES, AGENTS, DIRECTORS, MANAGERS, MEMBERS, EMPLOYEES,
              SUPPLIERS, LICENSORS, REPRESENTATIVES OR AGENTS BE LIABLE TO YOU
              FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES,
              LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO
              SPACEIT HEREUNDER OR $100.00, WHICHEVER IS GREATER.
            </p>
            <p>
              THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
              LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT
              LIABILITY, OR ANY OTHER BASIS, EVEN IF SPACEIT HAS BEEN ADVISED OF
              THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF
              LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN
              THE APPLICABLE JURISDICTION. SOME STATES DO NOT ALLOW THE
              EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO
              THE ABOVE LIMITATIONS OR EXCLUSIONS MAY NOT APPLY TO YOU. THESE
              TERMS GIVES YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER
              RIGHTS WHICH VARY FROM STATE TO STATE. THE DISCLAIMERS,
              EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THESE TERMS WILL
              NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
            </p>
            <p>
              The Website is controlled and operated from facilities in the
              United States. SpaceIt makes no representations that the Website
              is appropriate or available for use in other locations. Those who
              access or use the Website from other jurisdictions do so at their
              own volition and are entirely responsible for compliance with all
              applicable United States and local laws and regulations, including
              but not limited to export and import regulations. You may not use
              the Website if you are a resident of a country embargoed by the
              United States, or are a foreign person or entity blocked or denied
              by the United States government. Unless otherwise explicitly
              stated, all materials found on the Website are solely directed to
              individuals, companies, or other entities located in the United
              States.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>17. Indemnity.</strong> You agree to defend, indemnify and
              hold harmless SpaceIt and its subsidiaries, agents, licensors,
              managers, and other affiliated companies, and their employees,
              contractors, agents, officers and directors, from and against any
              and all claims, damages, obligations, losses, liabilities, costs
              or debt, and expenses (including but not limited to attorney's
              fees) arising from: (i) your use of and access to the Website and
              the Services, including any data or content transmitted or
              received by you; (ii) your violation of any term of these Terms,
              including without limitation your breach of any of the
              representations and warranties above; (iii) your violation of any
              third-party right, including without limitation any right of
              privacy or intellectual property rights; (iv) your violation of
              any applicable law, rule or regulation; (v) any of your User
              Content or any that is submitted via your account; (vi) any other
              party's access and use of the Website or the Services with your
              unique username, password or other appropriate security code; or
              (vii) your interactions with any other Authorized Users.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>18. Contact Us</strong>
            </p>
            <p>
              <strong>18.1. Contact for Alleged Copyright Infringement.</strong>{" "}
              SpaceIt respects the intellectual property rights of others and
              requires that its users do the same. If you believe that content
              on the Website or other activity taking place on the Website
              constitutes infringement of a work protected by copyright, please
              notify our agent, designated under the Digital Millennium
              Copyright Act (17 U.S.C. §512) (the “DMCA”) to respond to such
              concerns, as follows:
            </p>
            <div className="mb-2">
              <div>Spaceit, LLC</div>
              <div>1324 Harbor Road</div>
              <div>Hewlett Harbor, NY 11557</div>
            </div>
            <p>
              Your notice must comply with the DMCA. Upon receipt of a compliant
              notice, we will respond and proceed in accordance with the DMCA.
            </p>
            <p>
              <strong>
                18.2. Report Inaccurate Personally Identifiable Information.
              </strong>{" "}
              If you notice any inaccurate personally identifiable information
              on our Site that relates to you, please contact us by email or
              post to the email or postal address stated above.
            </p>
            <p>
              <strong>18.3. General Inquiries.</strong> If you have any
              questions about these Terms, the practices of this Website, or
              your dealings with SpaceIt, please contact us using the contact
              form at info@spaceit.com.
            </p>
            <p></p>
            <p></p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>19. Modifications to these Terms.</strong> We may modify
              and change these Terms over time. We will not “retroactively”
              change these Terms, and any modifications we make shall take
              effect proactively, once you next access the Website. Please feel
              free to print out a copy of the current Terms for your records.
            </p>
            <p></p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>20. Assignment.</strong> These Terms shall not be
              assignable by you, either in whole or in part. SpaceIt reserves
              the right to assign its rights and obligations under these Terms.
            </p>
            <p></p>
            <p></p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>
                21. Governing Law, Arbitration, and Class Action/Jury Trial
                Waiver.
              </strong>
            </p>
            <p>
              <strong>21.1. Governing Law.</strong> You agree that: (i) the
              Website and the Services shall be deemed solely based in the State
              of New York; and (ii) each of the Website and the Services shall
              be deemed a passive one that does not give rise to personal
              jurisdiction over us, either specific or general, in jurisdictions
              other than New York. These Terms shall be governed by the internal
              substantive laws of the State of New York, without respect to its
              conflict of laws principles. The parties acknowledge that these
              Terms evidence a transaction involving interstate commerce.
              Notwithstanding the preceding sentences with respect to the
              substantive law, any arbitration conducted pursuant to these Terms
              shall be governed by the Federal Arbitration Act (9 U.S.C. §§
              1-16). The application of the United Nations Convention on
              Contracts for the International Sale of Goods is expressly
              excluded. You agree to submit to the personal jurisdiction of the
              federal and state courts located in New York Count, New York for
              any actions for which we retain the right to seek injunctive or
              other equitable relief in a court of competent jurisdiction to
              prevent the actual or threatened infringement, misappropriation or
              violation of a our copyrights, trademarks, trade secrets, patents,
              or other intellectual property or proprietary rights, as set forth
              in the Arbitration provision below, including any provisional
              relief required to prevent irreparable harm. You agree that New
              York County, New York is the proper forum for any appeals of an
              arbitration award or for trial court proceedings in the event that
              the arbitration provision below is found to be unenforceable.
            </p>
            <p>
              <strong>21.2. Arbitration.</strong> READ THIS SECTION CAREFULLY
              BECAUSE IT REQUIRES THE PARTIES TO ARBITRATE THEIR DISPUTES AND
              LIMITS THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM SPACEIT.
            </p>
            <p>
              For any dispute with SpaceIt, you agree to first contact us at
              info@spaceit.com and attempt to resolve the dispute with us
              informally. In the unlikely event that SpaceIt has not been able
              to resolve a dispute it has with you after sixty (60) days, we
              each agree to resolve any claim, dispute, or controversy
              (excluding any claims for injunctive or other equitable relief as
              provided below) arising out of or in connection with or relating
              to this Agreement, or the breach or alleged breach thereof
              (collectively, “Claims”), by binding arbitration by JAMS, under
              the Optional Expedited Arbitration Procedures then in effect for
              JAMS, except as provided herein. JAMS may be contacted at
              www.jamsadr.com. The arbitration will be conducted in New York
              County, New York, unless you and SpaceIt agree otherwise. If you
              are using the Service for commercial purposes, each party will be
              responsible for paying any JAMS filing, administrative and
              arbitrator fees in accordance with JAMS rules, and the award
              rendered by the arbitrator shall include costs of arbitration,
              reasonable attorneys' fees and reasonable costs for expert and
              other witnesses. If you are an individual using the Website or the
              Services for non-commercial purposes: (i) JAMS may require you to
              pay a fee for the initiation of your case, unless you apply for
              and successfully obtain a fee waiver from JAMS; (ii) the award
              rendered by the arbitrator may include your costs of arbitration,
              your reasonable attorney's fees, and your reasonable costs for
              expert and other witnesses; and (iii) you may sue in a small
              claims court of competent jurisdiction without first engaging in
              arbitration, but this does not absolve you of your commitment to
              engage in the informal dispute resolution process. Any judgment on
              the award rendered by the arbitrator may be entered in any court
              of competent jurisdiction. Nothing in this Section shall be deemed
              as preventing SpaceIt from seeking injunctive or other equitable
              relief from the courts as necessary to prevent the actual or
              threatened infringement, misappropriation, or violation of our
              data security, intellectual property rights or other proprietary
              rights.
            </p>
            <p>
              <strong>21.3. Class Action/Jury Trial Waiver.</strong> WITH
              RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY
              HAVE OBTAINED OR USED THE WEBSITE OR THE SERVICES FOR PERSONAL,
              COMMERCIAL OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE
              PARTIES' INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS
              MEMBER IN ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE
              ATTORNEY GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS
              WAIVER APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE
              OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE
              PERSON'S CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU
              AND SPACEIT ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
              PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY
              GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF ANY KIND.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>22. General.</strong> If any provision of these Terms is
              held to be invalid or unenforceable, such provision shall be
              struck and the remaining provisions shall be enforced. Headings
              are for reference purposes only and in no way define, limit,
              construe, or describe the scope or extent of such section.
              SpaceIt's failure to act with respect to a breach by you or others
              does not waive its right to act with respect to subsequent or
              similar breaches. This agreement and the terms and conditions
              contained herein set forth the entire understanding and agreement
              between SpaceIt and you with respect to the subject matter hereof
              and supersede any prior or contemporaneous understanding, whether
              written or oral.
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>23. Survival.</strong> The following provisions shall
              survive the termination of these Terms and shall apply
              indefinitely: (i) Section 5 (Ownership; Reservation of Rights);
              (ii) Section 10 (Electronic Communications); (iii) Section 14
              (User Disputes); (iv) Section 15 (Warranty Disclaimer); (v)
              Section 16 (Limitation of Liability); (vi) Section 17 (Indemnity);
              (vii) Section 20 (Assignment); (viii) Section 21 (Governing Law,
              Arbitration, and Class Action/Jury Trial Waiver); (ix) Section 22
              (General); and (x) Section 23 (Survival).
            </p>
          </Col>
        </Row>

        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>24. Relationship to Privacy Policy.</strong> These Terms
              must be read in conjunction with our Privacy Policy. The
              provisions of our Privacy Policy are incorporated herein. To the
              extent these Terms conflict with the terms of our Privacy Policy,
              the terms of our Privacy Policy shall control.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md="8">
            <p>
              <strong>25. Effective Date. </strong>The effective date of these
              Terms is May 16, 2022.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </Layout>
)

export default Terms
